import React from 'react'
import PropTypes from 'prop-types'
import { TooltipAnchor, Tooltip } from '../../shared/Tooltip.jsx'
import bigArrowSVG from '../../../assets/images/big_arrow.svg'
import rubyConstants from '../../ruby_constants.js'
import {
  isAnywhereExceptPR,
  anywhere_except_pr_text,
} from '../../utilities/anywhere_except_pr.js'
import './RuleToFrom.scss'

const RuleToFrom = ({ rule, bannedStates, clinic_names }) => {
  const isBanned = (state) => bannedStates.has(state)

  // highlight banned states on the to side, not the from side
  const areaClassName = (state, dir) =>
    `state ${dir === 'to' && isBanned(state) ? 'banned' : ''}`

  const shortText = (areas, dir) => {
    if (isAnywhereExceptPR(areas)) return anywhere_except_pr_text
    const getClinicName = (id) => clinic_names[id] || `Clinic ${id}`

    return areas
      .sort((a, b) => a.state.localeCompare(b.state))
      .map((area) => (
        <span className={areaClassName(area.state, dir)} key={area.id}>
          {area.state === rubyConstants.State.NATIONAL
            ? rubyConstants.State.NATIONAL
            : area.state}
          {area.regions.length === 1 && ' (Region)'}
          {area.regions.length > 1 && ' (Regions)'}
          {dir === 'to' && area.clinic_ids.length > 0 && (
            <TooltipAnchor placement="right">
              {' ' /* <-- this space is important */}
              <a
                href={`/navigator_support_orgs/${rule.supportOrgId}#partner_providers`}
              >
                (Provider{area.clinic_ids.length > 1 ? 's' : ''})
              </a>
              <Tooltip>
                {area.clinic_ids.length === 1 ? (
                  getClinicName(area.clinic_ids[0])
                ) : (
                  <ul style={{ margin: '0' }} className="rule-to-provider-list">
                    {area.clinic_ids.map((id) => (
                      // it might not be safe to disable this rule but we don't have any other differentiators
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={id}>{getClinicName(id)}</li>
                    ))}
                  </ul>
                )}
              </Tooltip>
            </TooltipAnchor>
          )}
        </span>
      ))
      .map((span, i) => (
        // (it's safe to disable this rule because this list will never change)
        // eslint-disable-next-line react/no-array-index-key
        <span key={i}>
          {span}
          {i < areas.length - 1 ? ', ' : ' '}
        </span>
      ))
  }

  return (
    <div className="RuleToFrom from-to-row">
      <span className="from">
        {shortText(rule.from_areas, 'from', anywhere_except_pr_text)}
      </span>
      <span className="arrow">
        <img src={bigArrowSVG} alt="Arrow" />
      </span>
      <span className="to">
        {shortText(rule.to_areas, 'to', anywhere_except_pr_text)}
      </span>
    </div>
  )
}

const areaProps = {
  state: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  regions: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const fromAreaType = PropTypes.shape(areaProps)

const toAreaType = PropTypes.shape({
  ...areaProps,
  clinic_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
})

const ruleType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  supportOrgId: PropTypes.number.isRequired,
  from_areas: PropTypes.arrayOf(fromAreaType).isRequired,
  to_areas: PropTypes.arrayOf(toAreaType).isRequired,
})

RuleToFrom.propTypes = {
  rule: ruleType.isRequired,
  bannedStates: PropTypes.instanceOf(Set).isRequired,
  clinic_names: PropTypes.objectOf(PropTypes.string).isRequired,
}

export { RuleToFrom }
